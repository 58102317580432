/* BOXSIZING applies to all boxes, except the ones UNSET */
*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  height: 100%;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
